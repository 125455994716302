import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import useHidden from '@/hooks/useHidden';
import { cn } from '@arcsite/utils';
import { GlobalNav } from '@/pages/Admin/GlobalNav';
import MainMenu from '@/pages/Admin/mainMenu';
import logoShort from '@/assets/images/logo-short.svg';
import { useLogout } from '@/auth';
import { isOnline } from '@/utils/env';
import { useUser } from '@/hooks';
import { User } from '@/reducers/product-management';

// export const isLoginFromEnterprise =
//   document.cookie.includes('arc_enterprise_admin_login_as');

// export const isLoginFromEnterprise = true;

export function NavHeader({
  isLoginFromEnterprise,
}: {
  isLoginFromEnterprise: boolean;
}) {
  return (
    <div
      className={cn(
        'sticky left-0 right-0 bg-white-100 z-40 border-b border-slate-900/10',
        isLoginFromEnterprise ? 'top-[75px]' : 'top-0'
      )}
    >
      <div className="main-navigation-header h-14 flex items-center justify-between pl-4 sm:pl-6">
        <div className="flex items-center h-full">
          <Link to="/" className="mr-4">
            <img
              alt="home"
              className="h-12"
              src={logoShort}
              style={{ height: '36px' }}
            />
          </Link>
          <GlobalNav />
        </div>
        <div className="flex self-stretch justify-end text-xs">
          <div id="main-menu" className="self-stretch flex items-center">
            <MainMenu />
          </div>
        </div>
      </div>
    </div>
  );
}

const LoginInFromEnterpriseBanner = ({ user }: { user: User }) => {
  const goToConsole = () => {
    if (isOnline()) {
      // TODO: 线上环境需要跳转到 enterprise.arcsite.com ？
      alert('TODO: jump to online website');
    } else {
      window.location.href = 'https://hq-test.arcsite.com/';
    }
  };
  const logout = useLogout(goToConsole);
  const handleReturnToConsole = () => {
    logout();
  };

  const employeeName = [user?.first_name, user?.last_name]
    .filter(Boolean)
    .join(' ');

  return (
    <div className="z-50 h-[75px] w-screen !sticky top-0 pl-12 pr-9 py-4 bg-orange-e3571c text-white-100 flex justify-between items-center gap-2">
      <div className="flex flex-col">
        <span className="text-16 font-extrabold line1">
          Logged in as {employeeName}
        </span>
        <span className="text-14 leading-4.5 line2">
          You are currently logged into the ArcSite user site as {employeeName}.
          All actions will be recorded in the activity log. Note: Signing in
          does not grant access to signature requests.{' '}
          {/** TODO: 暂时没提供超链接，先隐藏 */}
          {/* <Link className="text-white-100 underline" target="_blank" to="/">
              Learn more
            </Link> */}
        </span>
      </div>
      <button
        onClick={handleReturnToConsole}
        className="flex-shrink-0 bg-white-100 cursor-pointer text-orange-e3571c px-4 py-1 border border-white-100 rounded"
      >
        Return to console
      </button>
    </div>
  );
};

const AppHeader: React.FC = () => {
  const user = useUser();
  const hiddenArray = useHidden();
  const headerHidden = hiddenArray.includes('header');
  const isLoginFromEnterprise = user?.is_enterprise_admin_login_as;
  return (
    <>
      {isLoginFromEnterprise && <LoginInFromEnterpriseBanner user={user} />}
      {!headerHidden && (
        <NavHeader isLoginFromEnterprise={isLoginFromEnterprise || false} />
      )}
      <Outlet />
    </>
  );
  //   if (!isLoginFromEnterprise) {
  //     return (
  //       <>
  //       { isLoginFromEnterprise&&<LoginInFromEnterpriseBanner/>}
  //         {!headerHidden && <Header />}
  //         <Outlet />
  //       </>
  //     );
  //   }

  //   return (
  //     <div className="flex flex-col h-full">
  //       <div className="z-50 h-[75px] w-screen !sticky top-0 pl-12 pr-9 py-4 bg-orange-e3571c text-white-100 flex justify-between items-center gap-2">
  //         <div className="flex flex-col">
  //           <span className="text-16 font-extrabold line1">
  //             Logged in as Time Rice
  //           </span>
  //           <span className="text-14 leading-4.5 line2">
  //             You are currently logged into the ArcSite user site as Tim Rice. All
  //             actions will be recorded in the activity log. Note: Signing in does
  //             not grant access to signature requests.{' '}
  //             {/** TODO: 暂时没提供超链接，先隐藏 */}
  //             {/* <Link className="text-white-100 underline" target="_blank" to="/">
  //               Learn more
  //             </Link> */}
  //           </span>
  //         </div>
  //         <button className="flex-shrink-0 bg-white-100 cursor-pointer text-orange-e3571c px-4 py-1 border border-white-100 rounded">
  //           Return to console
  //         </button>
  //       </div>
  //       {!headerHidden && <Header />}
  //       <div className="flex-1 overflow-auto">
  //         <Outlet />
  //       </div>
  //     </div>
  //   );
};

export default AppHeader;
