import AppStoreLogo from '@/assets/images/app-store-logo.svg';
import GoogleStoreLogo from '@/assets/images/google-store.svg';
import MsStoreLogo from '@/assets/images/ms-store.png';
import GalaxyStoreLogo from '@/assets/images/galaxy-store.svg';
import AppStoreQrCode from '@/assets/images/qr-code-ios.webp';
import GoogleStoreQrCode from '@/assets/images/qr-code-android.webp';
import MsStoreQrCode from '@/assets/images/qr-code-windows.webp';
import QrCode from '@/assets/images/down-load-qrcode.png';
import { twMerge } from 'tailwind-merge';
import tracker from '@/tracker';

const APP_LINK_MAP = {
  ios: {
    logo: AppStoreLogo,
    link: 'https://apps.apple.com/us/app/arcsite-floor-plans-and-cad/id986274256',
    qrCode: AppStoreQrCode,
  },
  android: {
    logo: GoogleStoreLogo,
    link: 'https://play.google.com/store/apps/details?id=com.arcsite.app.android',
    qrCode: GoogleStoreQrCode,
  },
  galaxy: {
    logo: GalaxyStoreLogo,
    link: 'https://galaxy.store/arcsite',
    qrCode: GoogleStoreQrCode,
  },
  windows: {
    logo: MsStoreLogo,
    link: 'https://apps.microsoft.com/store/detail/arcsite/9N9HM6J715VP',
    qrCode: MsStoreQrCode,
  },
};

const DownloadApp = () => {
  const onClick = (store: string) => {
    tracker.logEvent('web_download_app_modal_store_clicked', { store });
  };
  return (
    <div className="mt-8 mb-8 m:mx-8 w-full flex flex-col items-center justify-center">
      <div className="flex items-center flex-col sm:flex-row sm:justify-center sm:items-start sm:w-[501px] sm:mx-auto">
        <div className="flex flex-col items-center sm:w-[210px]">
          <p className="text-14 leading-[22px] text-black-85 mb-4 sm:mb-6">
            Scan the QR code to download:
          </p>
          <img
            alt="qr-code"
            className={twMerge('h-[176px] w-[176px] rounded-[8px]')}
            src={QrCode}
          />
        </div>
        <div className="hidden sm:!block w-[281px] h-[1px] bg-black-10 my-4 sm:my-0 sm:w-[1px] sm:h-[340px] sm:mx-[40px]"></div>
        <div className="hidden sm:!flex sm:w-[210px] flex-col items-center">
          <p className="text-14 leading-[22px] text-black-85 mb-6">
            Download links:
          </p>
          <a
            target="_blank"
            href="https://apps.apple.com/us/app/arcsite-floor-plans-and-cad/id986274256"
            rel="noreferrer"
            onClick={() => onClick('App Store')}
          >
            <img
              alt="app store"
              className={twMerge('h-12')}
              src={APP_LINK_MAP.ios.logo}
            />
          </a>
          <a
            target="_blank"
            href="https://play.google.com/store/apps/details?id=com.arcsite.app.android"
            rel="noreferrer"
            className="mt-4"
            onClick={() => onClick('Google Play')}
          >
            <img
              alt="google store"
              className={twMerge('h-12')}
              src={APP_LINK_MAP.android.logo}
            />
          </a>
          <a
            target="_blank"
            href="https://galaxy.store/arcsite"
            rel="noreferrer"
            className="mt-4"
            onClick={() => onClick('Galaxy Store')}
          >
            <img
              alt="windows"
              className={twMerge('h-12')}
              src={APP_LINK_MAP.galaxy.logo}
            />
          </a>
          <a
            target="_blank"
            href="https://apps.microsoft.com/store/detail/arcsite/9N9HM6J715VP"
            rel="noreferrer"
            className="mt-4"
            onClick={() => onClick('Microsoft Store')}
          >
            <img
              alt="windows"
              className={twMerge('h-12')}
              src={APP_LINK_MAP.windows.logo}
            />
          </a>
          <p className="text-12 leading-4 text-center text-black-55 mt-4">
            *
            <a
              href="https://www.arcsite.com/pricing"
              target="_blank"
              className="text-blue-398AE7 underline hover:underline"
              rel="noreferrer"
            >
              Pro and Premium
            </a>{' '}
            features currently require tablet devices.
          </p>
        </div>
        <div className="sm:hidden flex-col items-center mt-8">
          <p className="text-16 leading-[22px] text-black-85 mb-0 text-center">
            Links to download:
          </p>
          <div className="flex items-center sm:justify-center justify-between flex-wrap">
            <a
              target="_blank"
              href="https://apps.apple.com/us/app/arcsite-floor-plans-and-cad/id986274256"
              rel="noreferrer"
              className="mt-4"
              onClick={() => onClick('App Store')}
            >
              <img
                alt="app store"
                className={twMerge('h-12')}
                src={APP_LINK_MAP.ios.logo}
              />
            </a>
            <a
              target="_blank"
              href="https://play.google.com/store/apps/details?id=com.arcsite.app.android"
              rel="noreferrer"
              className="mt-4"
              onClick={() => onClick('Google Play')}
            >
              <img
                alt="google store"
                className={twMerge('h-12')}
                src={APP_LINK_MAP.android.logo}
              />
            </a>
            <a
              target="_blank"
              href="https://galaxy.store/arcsite"
              rel="noreferrer"
              className="mt-4"
              onClick={() => onClick('Galaxy Store')}
            >
              <img
                alt="windows"
                className={twMerge('h-12')}
                src={APP_LINK_MAP.galaxy.logo}
              />
            </a>
            <a
              target="_blank"
              href="https://apps.microsoft.com/store/detail/arcsite/9N9HM6J715VP"
              rel="noreferrer"
              className="mt-4"
              onClick={() => onClick('Microsoft Store')}
            >
              <img
                alt="windows"
                className={twMerge('h-12 rounded-lg')}
                src={APP_LINK_MAP.windows.logo}
              />
            </a>
          </div>
          <p className="text-12 leading-4 text-center text-black-55 mt-4">
            *
            <a
              href="https://www.arcsite.com/pricing"
              target="_blank"
              className="text-blue-398AE7 underline hover:underline"
              rel="noreferrer"
            >
              Pro and Premium
            </a>{' '}
            features currently require tablet devices.
          </p>
        </div>
      </div>
    </div>
  );
};

export default DownloadApp;
