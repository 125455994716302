import { combineReducers } from 'redux';
// @ts-ignore
import entities from './entities';
// @ts-ignore
import ui from './ui';
import { OrgAlliance } from '@/api';

// const store = {
//     entities: {
//         category: {
//             categoriesByUuid: {},
//             pagination: {
//                 search_all: {
//                     count: 200,
//                     pages: [
//                         {
//                             isFetching: false,
//                             isSuccess: false,
//                             lastUpdateTime: '',
//                             pageItems: {
//                                 0: 11,
//                                 1: 22,
//                             },
//                         },
//                     ],
//                 },
//             },
//         },
//         product: {
//             productsByUuid: {},
//             productUuids: [],
//             pagination: {
//                 count: 10,
//                 lastNum: 2,
//                 size: 10,
//             },
//         },
//     },

//     ui: {
//         activeCagegoryUuid: '',
//         editingCategory: {},
//         editingProduct: {},
//         copyingProducts: {},
//     },
// };

function getTasks() {
  const raw = localStorage.getItem('archiving-tasks');
  if (raw == null) {
    return [];
  }
  return JSON.parse(raw);
}
export const initialTasks = getTasks();

function archiveTasks(tasks: any = initialTasks, action: any) {
  if (action.type == 'ADD_TASK') {
    return [
      ...tasks,
      {
        taskId: action.taskId,
        projectName: action.projectName,
        drawingName: action.drawingName,
        progress: 0,
      },
    ];
  }
  if (action.type == 'UPDATE_TASK') {
    const taskIndex = tasks.findIndex((x: any) => x.taskId == action.taskId);
    return [
      ...tasks.slice(0, taskIndex),
      { ...tasks[taskIndex], progress: action.progress },
      ...tasks.slice(taskIndex + 1),
    ];
  }
  if (action.type == 'FINISH_TASK') {
    const taskIndex = tasks.findIndex((x: any) => x.taskId == action.taskId);
    return [...tasks.slice(0, taskIndex), ...tasks.slice(taskIndex + 1)];
  }
  if (action.type == 'CLEAR_TASK') {
    return [];
  }
  return tasks;
}

export type NotificationType = 'NONE' | 'ALL' | void;

type app = 'SERVICETITAN' | 'SALESFORCE' | 'I360' | 'QUICKBOOKS';

type IntegrationApp = {
  app: app;
  status: string;
};

export type User = {
  expired_without_extension: boolean;
  expired: boolean;
  username: string;
  company: string;
  job_title: string;
  default_org_purchase_type:
    | 'APPLE'
    | 'TRIAL'
    | 'STRIPE'
    | 'DIRECT'
    | 'GOOGLE_PLAY';
  default_org_user_level: string;
  default_org_type: 'INDIVIDUAL' | 'COMPANY';
  stripe_customer_id: string | null;
  company_logo_url?: string;
  org_profile: {
    logo_image_file?: {
      read_url: string;
    };
  };
  profile: {
    first_payment_due_date_enabled: boolean;
  };
  default_org_subs_expire_time: number;
  org_name: string;
  org_id: string;
  is_admin: boolean;
  email: string;
  email_notify: NotificationType;
  is_org_owner: boolean;
  first_name: string;
  last_name: string;
  phone: string;
  id: string;
  user_id: string;
  org_integration_apps: IntegrationApp[];
  features: {
    CAN_MANAGE_COLLABORATOR: boolean;
    CUSTOMIZE_FORM_TEMPLATE: boolean;
    PRODUCT_TAKEOFF: boolean;
    TAG_MANAGE: boolean;
    THIRD_PARTY_INTEGRATION: boolean;
    ENABLE_WISETACK: boolean;
    CAN_USE_ESIGN_BETA: boolean;
  };
  external_sf_org_id: string | void;
  org_alliances: OrgAlliance[];
  roles: string[];
  source: SignUpSource;
  industry: string | null;
  signup_industry_category: string | null;
  use_cases: string[];
  has_password: boolean;
  is_enterprise_admin_login_as: boolean;
};

export enum SignUpSource {
  WEB = 'WEB',
  WINDOWS = 'X_WINDOWS',
}

function user(state: User | null = null, action: any): User | null {
  if (action.type == 'LOGIN' || action.type == 'REFRESH') {
    return action.user;
  }
  if (action.type == 'LOGOUT') {
    return null;
  }
  return state;
}

export type Currency = {
  code: string;
  number: string;
  digits: number;
  currency: string;
  countries: string[];
  symbol: string;
  plural_name: string;
};

export type PricingMethod = {
  default_gross_margin: number;
  min_gross_margin: number;
};

export const defaultCurrencySettings = {
  currency: {
    code: 'USD',
    number: '840',
    digits: 2,
    currency: 'US Dollar',
    countries: ['United States of America (The)'],
    symbol: '$',
    plural_name: 'Dollars',
  },
};

function pricingMethod(
  state: PricingMethod | null = null,
  action: any
): PricingMethod | null {
  if (action.type == 'UPDATE_PRICE_METHOD') {
    return action.pricingMethod;
  }
  return state;
}

function drawerStatus(
  state: { status: 'OPEN' | 'CLOSE'; payload?: { width: number } } = {
    status: 'CLOSE',
    payload: { width: 610 },
  },
  action: { type: 'OPEN_DRAWER' | 'CLOSE_DRAWER'; payload?: { width: number } }
): { status: 'OPEN' | 'CLOSE'; payload?: { width: number } } {
  if (action.type === 'OPEN_DRAWER' || action.type === 'CLOSE_DRAWER') {
    return action.type === 'OPEN_DRAWER'
      ? { status: 'OPEN', payload: action.payload }
      : { status: 'CLOSE', payload: action.payload };
  }
  return state;
}

const rootReducer = combineReducers({
  entities,
  ui,
  archiveTasks,
  user,
  pricingMethod,
  drawerStatus,
});

export default rootReducer;
